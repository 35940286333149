import React from 'react';
import { Box, Typography } from '@mui/material';
import GeneralUseDialog from '@/components/Reusable/GeneralUse/Dialogs/GeneralUseDialog';

export const termsOfUseTitle = 'TERMS OF USE';

export const TermsOfUseText = (
  <Box>
    <Typography variant='subtitle1' gutterBottom>
      Version: September 2023
    </Typography>

    <Typography variant='h2' gutterBottom>
      1. SERVICES AND LICENSE GRANT
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>1.1</b> This End User License Agreement ("Cornerstone Terms") and the
      applicable Cornerstone Hub Purchase Agreement constitute the "Agreement"
      between Customer (as defined in the Purchase Agreement) and Cornerstone.
      Subject to and conditioned on Customer's compliance with the terms and
      conditions of this Agreement, Cornerstone hereby grants Customer a
      non-exclusive, non-transferable license to access and use the Services (as
      defined in the Purchase Agreement) during the Term, solely for use by
      Authorized Users in accordance with the terms and conditions herein. Such
      use is limited to Customer's internal use. If, and only if, Cornerstone
      incorporates any Cornerstone Hub Arising IP (as defined in Section 4.5)
      into the Services during the Term, then such incorporated Cornerstone Hub
      Arising IP is deemed included in all references to the Services and will
      be subject to these Cornerstone Terms for so long as such Cornerstone Hub
      Arising IP remains so incorporated into the Services.
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>1.2</b> The total number of Authorized Users will not exceed the number
      set forth in the Purchase Agreement unless such increase is requested by
      Customer. "Authorized User" means Customer's employees, consultants,
      contractors, and agents (i) who are authorized by Customer to access and
      use the Services under the rights granted to Customer pursuant to this
      Agreement and (ii) for whom access to the Services has been purchased
      hereunder. Customer may request additional Authorized User licenses via
      email to Cornerstone. If approved, Customer shall be billed the following
      billing cycle for such additional licenses.
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>1.3</b> Cornerstone reserves the right, in its sole discretion, to make
      any changes to the Services that it deems necessary or useful to: (a)
      maintain or enhance: (i) the quality or delivery of Cornerstone's services
      to its customers; (ii) the competitive strength of or market for
      Cornerstone's services; or (iii) the Services' cost efficiency or
      performance; or (b) to comply with applicable law; provided, however, any
      changes shall not materially diminish the features or functionality of the
      Services.
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>1.4</b> Cornerstone may, directly or indirectly, suspend, terminate, or
      otherwise deny Customer's or any other person's access to or use of all or
      any part of the Services, without incurring any resulting obligation or
      liability, if: (a) Cornerstone receives a judicial or other governmental
      demand or order, subpoena, or law enforcement request that expressly or by
      reasonable implication requires Cornerstone to do so; or (b) Cornerstone
      believes, in its good faith and reasonable discretion, that: (i) Customer
      has failed to comply with any material term of this Agreement, or accessed
      or used the Services beyond the scope of the rights granted or for a
      purpose not authorized under this Agreement; (ii) Customer has been, or is
      likely to be involved in any fraudulent, misleading, or unlawful
      activities; or (iii) this Agreement expires or is terminated. This Section
      1.4 does not limit any of Cornerstone's other rights or remedies, whether
      at law, in equity, or under this Agreement.
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>1.5</b> The Services are enhanced frequently. Updating service,
      including all software improvements, content updates will be provided to
      Customer at no additional cost during the Term.
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>1.6</b> Customer's use of the Services are governed by this Agreement
      as well as the Cornerstone website terms of service, which may be updated
      from time to time by Cornerstone without notice to Customer. The website
      terms of service are incorporated into this Agreement by this reference
      and made a part hereof. In the event of any inconsistency or conflict
      between the website terms of service and this Agreement, first the terms
      of this Agreement shall prevail, followed by the website terms of service.
    </Typography>

    <Typography variant='h2' gutterBottom>
      2. RESTRICTIONS AND RESPONSIBILITIES
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>2.1</b> Customer will not, directly or indirectly: reverse engineer,
      decompile, disassemble or otherwise attempt to discover the source code,
      object code or underlying structure, ideas, know-how or algorithms
      relevant to the Services; copy, modify, translate, or create derivative
      works based on the Services (except to the extent expressly permitted by
      Cornerstone or authorized within the Services); rent, lease, lend, sell,
      sublicense, assign, distribute, publish, transfer, or otherwise make
      available any Services to any person, including on or in connection with
      the internet or any time- sharing, service bureau, software as a service,
      cloud, or other technology or service or otherwise provide the Services
      for the benefit of a third party; access or use the Services in any manner
      or for any purpose that infringes, misappropriates, or otherwise violates
      any intellectual property right or other right of any third party, or that
      violates any applicable law; access or use the Services for purposes of
      competitive analysis of the Services, the development, provision, or use
      of a competing software service or product or any other purpose that is to
      Cornerstone's detriment or commercial disadvantage; remove any proprietary
      notices or labels; or otherwise access or use the Services beyond the
      scope of the authorization granted under this Agreement.
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>2.2</b> Customer acknowledges that any modifications it or its
      Authorized Users may make to the software configuration could result in
      malfunction or unintended operations of the Services. Cornerstone is not
      responsible for any delay or failure of performance caused in whole or in
      part by Customer's modifications.
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>2.3</b> Customer represents, covenants, and warrants that Customer will
      use the Services only in compliance with all applicable laws and
      regulations. Although Cornerstone has no obligation to monitor Customer's
      use of the Services, Cornerstone may do so and may prohibit any use of the
      Services it believes may be (or alleged to be) in violation of the
      foregoing.
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>2.4</b> Customer shall be responsible for obtaining and maintaining any
      equipment and ancillary services needed to connect to, access or otherwise
      use the Services, including, without limitation, modems, hardware,
      servers, software, operating systems, networking, web servers and the like
      (collectively, "Equipment"). Customer shall also be responsible for
      maintaining the security of the Equipment, Customer account, passwords
      (including but not limited to administrative and user passwords) and
      files, and for all uses of Customer account or the Equipment with or
      without Customer's knowledge or consent.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>2.5</b> Customer shall at all times during the Term: set up, maintain,
      and operate in good repair all Customer Systems (as defined below) on or
      through which the Services are accessed or used; and (b) provide all
      cooperation and assistance as Cornerstone may reasonably request to enable
      Cornerstone to exercise its rights and perform its obligations under and
      in connection with this Agreement.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>2.6</b> Cornerstone is not responsible or liable for any delay or
      failure of performance caused in whole or in part by Customer's delay in
      performing, or failure to perform, any of its obligations under this
      Agreement.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>2.7</b> If Customer becomes aware of any actual or threatened activity
      prohibited by Section 2.3, Customer shall immediately: (a) take all
      reasonable and lawful measures within their respective control that are
      necessary to stop the activity or threatened activity and to mitigate its
      effects; and (b) notify Cornerstone of any such actual or threatened
      activity.
    </Typography>

    <Typography variant='h2' gutterBottom>
      3. SECURITY
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>3.1</b> Cornerstone employs security measures in accordance with
      applicable industry practice and standards, applicable laws, rules, and
      regulations.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>3.2</b> Customer has and will retain sole responsibility for: (a) all
      Customer Data (as defined below), including its content and use; (b) all
      information, instructions, and materials provided by or on behalf of
      Customer in connection with the Services; (c) Customer's information
      technology infrastructure, including computers, software, databases,
      electronic systems (including database management systems), and networks,
      whether operated directly by Customer or through the use of third-party
      services ("Customer Systems"); (d) the security and use of Customer's
      access credentials; and (e) all access to and use of the Services directly
      or indirectly by or through the Customer Systems access credentials, with
      or without Customer's knowledge or consent, including all results obtained
      from, and all conclusions, decisions, and actions based on, such access or
      use.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>3.3</b> Customer shall employ all commercially reasonable physical,
      administrative, and technical controls, screening, and security procedures
      and other safeguards designed to: (a) securely administer the distribution
      and use of all access credentials and protect against any unauthorized
      access to or use of the Services; and (b) control the content and use of
      Customer Data, including the uploading or other provision of Customer Data
      for processing by the Services.
    </Typography>

    <Typography variant='h2' gutterBottom>
      4. CONFIDENTIALITY; PROPRIETARY RIGHTS
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>4.1</b> Each party (the "Receiving Party") understands that the other
      party (the "Disclosing Party") has disclosed or may disclose business,
      technical, pricing information, compensation information, know-how,
      compilations, processes, data protection, or financial information
      relating to the Disclosing Party's business (hereinafter referred to as
      "Proprietary Information" of the Disclosing Party). Proprietary
      Information of Cornerstone includes non-public information regarding
      features, functionality and performance of the Services. Proprietary
      Information of Customer includes non-public data provided by Customer to
      Cornerstone to enable the provision of the Services ("Customer Data"). The
      Receiving Party agrees: (i) to take reasonable precautions to protect such
      Proprietary Information, and (ii) not to use or divulge (except in
      performance of the Services or as otherwise permitted herein) to any third
      person any such Proprietary Information. The Disclosing Party agrees that
      the foregoing shall not apply with respect to any information after five
      (5) years following the disclosure thereof or any information that the
      Receiving Party can document (a) is or becomes generally available to the
      public, or (b) was in its possession or known by it prior to receipt from
      the Disclosing Party, or (c) was rightfully disclosed to it without
      restriction by a third party, (d) was independently developed without use
      of any Proprietary Information of the Disclosing Party, or (e) is required
      to be disclosed by law.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>4.2</b> Customer shall own all right, title and interest in and to the
      Customer Data. Customer hereby permits Cornerstone such limited rights and
      permissions in or relating to Customer Data as are necessary for
      Cornerstone to enforce this Agreement and exercise Cornerstone's rights
      and perform its obligations hereunder.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>4.3</b> Cornerstone, or its third-party licensors, shall own and retain
      all right, title and interest in and to (a) the Services and software, all
      improvements, enhancements or modifications thereto, and (b) all
      intellectual property rights related to any of the foregoing. Nothing in
      this Agreement grants any right, title, or interest in or to (including
      any license under) any intellectual property rights in or relating to the
      Services whether expressly, by implication, estoppel, or otherwise.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>4.4</b> Notwithstanding anything to the contrary, Cornerstone, and its
      Subcontractors, shall have the right to collect and analyze data and other
      information relating to and solely for the purpose of the provision, use
      and performance of various aspects of the Services and related systems and
      technologies (including, without limitation, information concerning
      Customer Data and data derived therefrom such as usage reports, user
      statistics, and other inputs into Cornerstone's artificial intelligence
      functionalities ("Resultant Data")), and Cornerstone will be free (during
      and after the term hereof) to (i) use Resultant Data to improve and
      enhance the Services and for other development, diagnostic and corrective
      purposes in connection with the Services and other Cornerstone offerings,
      and (ii) disclose Resultant Data solely in aggregate or other de-
      identified form in connection with its business. No rights or licenses are
      granted except as expressly set forth herein.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>4.5</b> "Cornerstone Hub Arising IP" means all technology that: (i) is
      or has been generated, developed, fixed in a tangible medium, conceived or
      reduced to practice by or on behalf of either party before, on or after
      the date this Agreement takes effect; and (ii) arises from or in
      connection with any execution, operation or use of the Cornerstone Hub
      (including the Cornerstone Hub processing, storing, organizing, arranging,
      selecting, aggregating, adapting or otherwise using or exploiting any data
      (including Licensee Data), content, information, materials or other inputs
      transmitted, submitted or uploaded to the Cornerstone Hub). Without
      limiting the foregoing and for clarity, the Cornerstone Hub Arising IP
      includes any updates, error corrections, bug fixes, enhancements or
      improvements to the Cornerstone Hub developed by or on behalf of Customer.
    </Typography>

    <Typography variant='h2' gutterBottom>
      5. PAYMENT OF FEES
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>5.1</b> Subject to exceptions under this Section 5 or any Free Trial
      Periods under Section 6.1, Customer will pay Cornerstone the then
      applicable fees described in the Purchase Agreement for the Services in
      accordance with the terms therein (the "Fees"). Cornerstone reserves the
      right to change the Fees or applicable charges and to institute new
      charges and Fees at the end of the Term, upon thirty (30) days prior
      notice to Customer (which may be sent by email). If Customer believes that
      Cornerstone has billed Customer incorrectly, Customer must contact
      Cornerstone no later than 60 days after the closing date on the first
      billing statement in which the error or problem appeared, in order to
      receive an adjustment or credit. All charges are fully earned upon payment
      and there are no refunds or credits for partially used monthly or annual
      subscriptions, as applicable. Customer must cancel the applicable
      subscription before automatic renewal in order to avoid account charges
      for the next applicable billing cycle.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>5.2</b> Cornerstone shall bill through an invoice, in which case, full
      payment for invoices issued in any given month must be received by
      Cornerstone prior to the following month's services being rendered. Unpaid
      amounts are subject to a finance charge of 1.5% per month on any
      outstanding balance, or the maximum permitted by law, whichever is lower,
      plus all expenses of collection and may result in immediate termination of
      Service. Customer shall be responsible for all taxes associated with
      Services other than U.S. taxes based on Cornerstone's net income.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>5.3</b> All amounts due under this Agreement shall be paid by credit
      card or other payment method agreed to by Cornerstone. Customer hereby
      authorizes us to charge the payment method for: (i) Fees and applicable
      Taxes for each billing period during the Term on the same date on which
      Customer registered to use the Services; and (ii) any applicable setup
      Fees and applicable Taxes on the Effective Date of this Agreement
      (collectively, the "Payment Date"). Customer further authorizes
      Cornerstone to use a third party to process such payments, and hereby
      consent to the disclosure of Customer's billing information to such third
      party. Customer shall promptly provide Cornerstone with updated credit
      card information in the event that Customer's credit card on file is no
      longer valid. If the credit card information on file with Cornerstone is
      not valid at any time during the Term, or if Customer's credit card cannot
      be processed on any Payment Date, (i) Customer hereby authorizes
      Cornerstone to continue to attempt to charge the amounts due until such
      amounts are paid in full; and (ii) Cornerstone reserves the right to
      immediately terminate this Agreement, or suspend Customer's access to the
      Services. If at any time Customer's access to the Services is suspended or
      disabled (as applicable) for non-payment, Cornerstone may charge a
      reactivation fee to reinstate them. Cornerstone reserves the right to stop
      accepting credit card payments from one or more issuers upon written
      notice to Customer.
    </Typography>

    <Typography variant='h2' gutterBottom>
      6 TERMS OF USE
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>6.1</b> Customer accepts sole responsibility for its use of the
      Cornerstone Hub and for the effects of such use on the Cornerstone Hub and
      its functionality and operation, including without limitation such effects
      with respect to Customer, Cornerstone, and all Cornerstone Hub users.
      Customer acknowledges and agrees that Cornerstone shall have no
      responsibility whatsoever with respect to any use of the Cornerstone Hub
      or any consequences of such use, including without limitation any outcomes
      with respect to (i) delivery of documents (e.g. email via the
      functionality and operation of Cornerstone Hub's hosted software and
      hosted software service); (ii) issuance of tickets for airline travel or
      other mode of transportation (e.g. ticketing via the functionality and
      operation of Cornerstone's hosted software and hosted software service);
      or (iii) charges and fees incurred with any travel industry Global
      Distribution System ("GDS," e.g. Amadeus® or Sabre®), which GDS charges
      and fees, if any, you agree are your sole responsibility. You agree to
      promptly reimburse Cornerstone for any such GDS charges or fees.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>6.2</b> Cornerstone does not provide technical or other support for the
      Cornerstone Hub or its use. Cornerstone may in its sole discretion, make
      available, with or without license fee or other charges, software updates
      to the Cornerstone Hub, which updates shall be governed by this Agreement
      and use of which updates may be required for continued operation of the
      Cornerstone Hub. You agree to promptly notify Cornerstone of any error you
      discover in the operation of the Cornerstone Hub or its operation.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>6.3</b> Cornerstone may, at its sole discretion, at the beginning of or
      during a Term (as defined below), authorize free trial periods to
      particular functions of the Services or entire licenses (each a "Free
      Trial Period"). Once a Free Trial Period of a particular function ends,
      and upon prior written notice to Customer, which may be by email,
      Cornerstone will begin billing Customer's payment method for periodic
      subscription fees (plus any applicable taxes), unless Customer cancels
      prior to the end of the Free Trial Period.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>6.4</b> Subject to earlier termination as provided below, this
      Agreement begins as of the Effective Date and continues for the Initial
      Service Term as specified in the Purchase Agreement, and shall be
      automatically renewed for additional periods of the same duration as the
      Initial Service Term (each, a "Renewal Term;" any Free Trial Period, the
      Initial Term, and any Renewal Term shall collectively be referred to as
      the "Term"), unless either party requests termination at least thirty (30)
      days prior to the end of the then-current Term. Cornerstone reserves the
      right to discontinue any particular service, feature, or function (i)
      prior to the start of an Initial Term, or (ii) before any Renewal Term
      upon thirty (30) days' notice to Customer.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>6.5</b> Cornerstone may suspend Customer's access to the Services and
      disable Customer's presence on the Services if any payment is past due and
      such failure to pay will be considered a material breach of this
      Agreement.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>6.6</b> In addition to any other remedies it may have, either party may
      also terminate this Agreement upon (i) written notice, if the other party
      materially breaches any of the terms or conditions of this Agreement and
      such breach (A) is incapable of cure; or (B) being capable of cure,
      remains uncured 30 days after the non-breaching party provides the
      breaching Party with written notice of such breach, or (ii) effective
      immediately upon written notice to the other Party, if the other Party:
      (A) becomes insolvent or is generally unable to pay, or fails to pay, its
      debts as they become due; (B) files or has filed against it, a petition
      for voluntary or involuntary bankruptcy or otherwise becomes subject,
      voluntarily or involuntarily, to any proceeding under any domestic or
      foreign bankruptcy or insolvency law; (C) makes or seeks to make a general
      assignment for the benefit of its creditors; or (D) applies for or has
      appointed a receiver, trustee, custodian, or similar agent appointed by
      order of any court of competent jurisdiction to take charge of or sell any
      material portion of its property or business.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>6.7</b> All sections of this Agreement which by their nature should
      survive termination will survive termination, including, without
      limitation, accrued rights to payment, intellectual property,
      confidentiality obligations, warranty disclaimers, limitations of
      liability, survival, effect of termination, and the Miscellaneous
      provisions under Section 12.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>6.8</b> Upon termination of this Agreement all rights granted hereunder
      to Customer will immediately cease, and Customer will immediately cease
      all access and use of the Services. Customer will pay in full for the
      Services provided up to and including the last day on which the Services
      are provided.
    </Typography>

    <Typography variant='h2' gutterBottom>
      7. WARRANTY AND DISCLAIMER
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>7.1</b> Each party represents and warrants to the other party that (i)
      it is duly organized, validly existing, and in good standing as a
      corporation or other entity under the Laws of the jurisdiction of its
      incorporation or other organization; (ii) it has the full right, power,
      and authority to enter into and perform its obligations and grant the
      rights, licenses, consents, and authorizations it grants or is required to
      grant under this Agreement; (iii) the execution of this Agreement by its
      representative whose signature is set forth at the end of this Agreement
      has been duly authorized by all necessary corporate or organizational
      action of such party; and (iv) when executed and delivered by both
      parties, this Agreement will constitute the legal, valid, and binding
      obligation of such party, enforceable against such party in accordance
      with its terms.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>7.2</b> Cornerstone shall use reasonable efforts consistent with
      prevailing industry standards to maintain and perform the Services in a
      manner which minimizes errors and interruptions in the Services and in a
      professional and workmanlike manner. Services may be temporarily
      unavailable for scheduled maintenance or for unscheduled emergency
      maintenance, either by Cornerstone or by third-party providers, or because
      of other causes beyond Cornerstone's reasonable control, but Cornerstone
      shall use reasonable efforts to provide advance notice in writing or by
      e-mail of any scheduled service disruption.
    </Typography>

    <Typography variant='body1' gutterBottom>
      HOWEVER, CORNERSTONE DOES NOT WARRANT THAT THE SERVICES WILL BE
      UNINTERRUPTED OR ERROR FREE; NOR DOES IT MAKE ANY WARRANTY AS TO THE
      RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES. EXCEPT AS EXPRESSLY
      SET FORTH IN THIS SECTION, THE SERVICES ARE PROVIDED "AS IS" AND
      CORNERSTONE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
      NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
      PARTICULAR PURPOSE AND NON-INFRINGEMENT.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>7.3</b> Customer represents, warrants, and covenants to Cornerstone
      that Customer owns or otherwise has and will have the necessary rights and
      consents in and relating to the Customer Data so that, as received by
      Cornerstone and used in accordance with this Agreement, they do not and
      will not infringe, misappropriate, or otherwise violate any intellectual
      property rights, or any privacy or other rights of any third party or
      violate any applicable law.
    </Typography>

    <Typography variant='h2' gutterBottom>
      8. INDEMNITY
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>8.1</b> Cornerstone shall hold Customer harmless from liability to
      third parties resulting from infringement by the Service of any United
      States patent or any copyright or misappropriation of any trade secret,
      provided Cornerstone is promptly notified of any and all threats, claims
      and proceedings related thereto and given reasonable assistance and the
      opportunity to assume sole control over defense and settlement;
      Cornerstone will not be responsible for any settlement it does not approve
      in writing. The foregoing obligations do not apply with respect to
      portions or components of the Service (i) not supplied by Cornerstone,
      (ii) made in whole or in part in accordance with Customer specifications,
      (iii) that are modified after delivery by Cornerstone, (iv) combined with
      other products, processes or materials where the alleged infringement
      relates to such combination, (v) where Customer continues allegedly
      infringing activity after being notified thereof or after being informed
      of modifications that would have avoided the alleged infringement, or (vi)
      where Customer's use of the Service is not strictly in accordance with
      this Agreement. If, due to a claim of infringement, the Services are held
      by a court of competent jurisdiction to be or are believed by Cornerstone
      to be infringing, Cornerstone may, at its option and expense (a) replace
      or modify the Service to be non-infringing provided that such modification
      or replacement contains substantially similar features and functionality,
      (b) obtain for Customer a license to continue using the Service, or (c) if
      neither of the foregoing is commercially practicable, terminate this
      Agreement and Customer's rights hereunder and provide Customer a refund of
      any prepaid, unused fees for the Service.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>8.2</b> Customer shall indemnify, defend, and hold harmless Cornerstone
      and its officers, directors, employees, agents, successors, and assigns
      (each, a "Cornerstone Indemnitee") from and against any and all losses
      incurred by such Cornerstone Indemnitee resulting from any action by a
      third party that arise out of or result from, or are alleged to arise out
      of or result from: (i) Customer Data, including any processing of Customer
      Data by or on behalf of Cornerstone in accordance with this Agreement;
      (ii) any other materials or information provided by or on behalf of
      Customer, including Cornerstone's compliance with any specifications or
      directions provided by or on behalf of Customer to the extent prepared
      without any contribution by Cornerstone; or (iii) allegation of facts
      that, if true, would constitute Customer's breach of any of its
      representations, warranties, covenants, or obligations under this
      Agreement. Customer shall further indemnify, defend, and hold harmless
      Cornerstone and its officers, directors, employees, agents, successors,
      and assigns from and against any and all losses incurred resulting from
      any modification made to the software by Customer.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>8.3</b> Each party shall promptly notify the other party in writing of
      any action for which such party believes it is entitled to be indemnified
      pursuant to Section 8.1 or 8.2, as the case may be. The party seeking
      indemnification (the "Indemnitee") shall cooperate with the other party
      (the "Indemnitor") at the Indemnitor's sole cost and expense. Indemnitor
      shall promptly assume control of the defense and shall employ counsel of
      its choice to handle and defend the same, at Indemnitor's sole cost and
      expense. Indemnitee may participate in and observe the proceedings at its
      own cost and expense with counsel of its own choosing. Indemnitor shall
      not settle any Action on any terms or in any manner that adversely affects
      the rights of any Indemnitee without Indemnitee's prior written consent,
      which shall not be unreasonably withheld or delayed. If Indemnitor fails
      or refuses to assume control of the defense of such Action, Indemnitee
      shall have the right, but no obligation, to defend against such`` Action,
      including settling such action after giving notice to Indemnitor, in each
      case in such manner and on such terms as Indemnitee may deem appropriate.
      Indemnitee's failure to perform any obligations under this Section 8.3
      will not relieve Indemnitor of its obligations under this Section 8,
      except to the extent that Indemnitor can demonstrate that it has been
      materially prejudiced as a result of such failure.
    </Typography>

    <Typography variant='h2' gutterBottom>
      9. LIMITATION OF LIABILITY
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>9.1</b> NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY
      INJURY OF A PERSON, CORNERSTONE AND ITS SUPPLIERS (INCLUDING BUT NOT
      LIMITED TO ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES,
      REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE OR
      LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS AND
      CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT
      LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR
      LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF
      SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR ANY
      INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR
      ANY MATTER BEYOND CORNERSTONE'S REASONABLE CONTROL; (D) FOR ANY CLAIMS
      ARISING OUT OF CUSTOMER'S MODIFICATION OF THE SOFTWARE; OR (E) FOR ANY
      AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS,
      EXCEED THE FEES PAID BY CUSTOMER TO CORNERSTONE FOR THE SERVICES UNDER
      THIS AGREEMENT IN THE 6 MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE
      LIABILITY, IN EACH CASE, WHETHER OR NOT CORNERSTONE HAS BEEN ADVISED OF
      THE POSSIBILITY OF SUCH DAMAGES.
    </Typography>

    <Typography variant='h2' gutterBottom>
      10. PUBLICITY
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>10.1</b> Each party hereby grants to the other party a limited,
      revocable license to use the party's name and related indicia in the other
      party's lists of current customers or vendors in promotional and marketing
      materials. In addition, Customer authorizes Cornerstone to provide its
      name and contact information to other Cornerstone customers as part of any
      referral network offered by Cornerstone as a customer resource. This
      provision shall survive termination or expiration of this Agreement.
    </Typography>

    <Typography variant='h2' gutterBottom>
      11. MISCELLANEOUS
    </Typography>

    <Typography variant='body1' gutterBottom>
      <b>11.1</b> If any provision of this Agreement is found to be
      unenforceable or invalid, that provision will be limited or eliminated to
      the minimum extent necessary so that this Agreement will otherwise remain
      in full force and effect and enforceable.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>11.2</b> This Agreement is not assignable, transferable or
      sublicensable by Customer except with Cornerstone's prior written consent.
      Cornerstone may transfer and assign any of its rights and obligations
      under this Agreement without consent.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>11.3</b> This Agreement is the complete and exclusive statement of the
      mutual understanding of the parties and supersedes and cancels all
      previous written and oral agreements, communications and other
      understandings relating to the subject matter of this Agreement. All
      waivers and modifications must be in writing signed by both parties,
      except as otherwise provided herein.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>11.4</b> No agency, partnership, joint venture, or employment is
      created as a result of this Agreement and Customer does not have any
      authority of any kind to bind Cornerstone in any respect whatsoever.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>11.5</b> In any action or proceeding to enforce rights under this
      Agreement, the prevailing party will be entitled to recover costs and
      attorneys' fees.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>11.6</b> All notices under this Agreement will be in writing and will
      be deemed to have been duly given when received, if personally delivered;
      when receipt is electronically confirmed, if transmitted by facsimile or
      e-mail; the day after it is sent, if sent for next day delivery by
      recognized overnight delivery service; and upon receipt, if sent by
      certified or registered mail, return receipt requested.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>11.7</b> This Agreement shall be governed by the laws of the State of
      Indiana without regard to its conflict of laws provisions.
    </Typography>
    <Typography variant='body1' gutterBottom>
      <b>11.8</b> ANY CONTROVERSY OR CLAIM ARISING OUT OF OR RELATING TO THIS
      CONTRACT, OR THE BREACH THEREOF, SHALL BE SETTLED BY ARBITRATION
      ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION IN CHICAGO, ILLINOIS,
      IN ACCORDANCE WITH ITS COMMERCIAL ARBITRATION RULES APPLYING INDIANA LAW,
      AND JUDGMENT ON THE AWARD RENDERED BY THE ARBITRATOR(S) MAY BE ENTERED IN
      ANY COURT HAVING JURISDICTION THERE.
    </Typography>
  </Box>
);

function TermsOfUseDialog({ open, setOpen }) {
  return (
    <GeneralUseDialog
      open={open}
      setOpen={setOpen}
      contentTitle={termsOfUseTitle}
      contentText={TermsOfUseText}
    />
  );
}

export default TermsOfUseDialog;
