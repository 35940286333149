import React, { useRef, useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import { useRouter } from 'next/router';
import Markdown from 'react-markdown';
import useGet from '@/utils/hooks/api/vercel/useGet';
import InfoDrawer from '@/components/Reusable/InfoDrawer/InfoDrawer';
import HelpButton from '@/components/Reusable/InfoDrawer/HelpButton';
import AutoComplete from '@/components/Reusable/Search/AutoComplete';

export default function HelpPanel() {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const drawerRef = useRef();

  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [displayedHelpPanel, setDisplayedHelpPanel] = useState();

  const { data: helpPanels, isLoading } = useGet('/helpPanels');

  // Populate the dropdown options from help panel data.
  useEffect(() => {
    if (isLoading) return;
    setDropDownOptions(parseHelpPanels(Object.values(helpPanels)));
  }, [isLoading]);

  // Any time the URL changes, update the displayed help panel to that page's.
  useEffect(() => {
    if (isLoading) return;
    setPanelForCurrentPage();
  }, [router.pathname, router.query.tab, isLoading]);

  function buildDropDownOption(panel) {
    if (!panel) return {};
    const titleLine = panel.split('\n')[0].replace('#', '').trim();
    const [group, label] = titleLine.split(' - ');
    return {
      label: label || 'N/A',
      value: panel,
      group: group || 'N/A',
      showByDefault: true,
    };
  }

  function setPanelForCurrentPage() {
    const path = router.pathname.slice(1); // Remove leading slash.
    const tab = router.query.tab;
    setDisplayedHelpPanel(
      buildDropDownOption(
        helpPanels[`${path}/${tab}.md`] ||
          helpPanels[`${path}/default.md`] ||
          helpPanels['default.md']
      )
    );
  }

  function parseHelpPanels(panels) {
    return panels
      .map((panel) => {
        const basePanel = buildDropDownOption(panel);

        const sections = panel.split('## ');
        const sectionPanels = sections.slice(1).map((section) => {
          const naiveOption = buildDropDownOption(section);
          return {
            ...naiveOption,
            label: `${basePanel.label} - ${naiveOption.group}`,
            group: basePanel.group,
            showByDefault: false,
            parent: basePanel,
          };
        });
        return [basePanel, ...sectionPanels];
      })
      .flat()
      .sort((a, b) => a.group > b.group);
  }

  function filterOptions(options, state) {
    if (state.inputValue.length === 0) {
      return options.filter((e) => e.showByDefault);
    }
    const fuse = new Fuse(
      options.filter((e) => !e.showByDefault).map((e) => e),
      {
        threshold: 0.4,
        keys: ['value', 'label'],
        ignoreLocation: true,
      }
    );
    return fuse
      .search(state.inputValue)
      .map((e) => e.item)
      .sort((a, b) => a.group > b.group);
  }

  return (
    <>
      <HelpButton
        onClick={() => {
          if (open) {
            setOpen(false);
          } else {
            setPanelForCurrentPage();
            drawerRef?.current?.openDrawer() || setOpen(true);
          }
        }}
      />
      <InfoDrawer open={open} setOpen={setOpen} ref={drawerRef}>
        <AutoComplete
          options={dropDownOptions}
          label='Search help articles'
          groupBy='group'
          width='100%'
          filterSelectedOptions={false}
          onChange={(_, newValue) => {
            if (newValue?.parent) {
              newValue = newValue.parent;
            }
            setDisplayedHelpPanel(newValue);
          }}
          value={displayedHelpPanel}
          filterOptions={filterOptions}
          isOptionEqualToValue={(option, value) => option.label === value.label}
        />
        <Markdown>{displayedHelpPanel?.value}</Markdown>
      </InfoDrawer>
    </>
  );
}
