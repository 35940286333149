import React from 'react';
import GeneralUseDialog from '@/components/Reusable/GeneralUse/Dialogs/GeneralUseDialog';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  listIcon: {
    minWidth: '20px',
    fontSize: '8px',
  },
}));

export const PrivacyPolicyText = () => {
  const { classes } = useStyles();
  return (
    <Box>
      <Typography variant='subtitle1' gutterBottom>
        Version: September 2023
      </Typography>

      <Typography variant='body1' gutterBottom>
        Cornerstone, an Indiana corporation ("Cornerstone" "we," "us," or "our")
        operates the websites located at&nbsp;
        <a href='http://www.ciswired.com/'>ciswired.com&nbsp;</a>
        and other related sites (collectively, the "Site") offering services
        information, industry content, and other various open-source software
        offerings (together with the Site, the "Services").
      </Typography>

      <Typography variant='body1' gutterBottom>
        Cornerstone respects and protects the privacy of the users that use our
        Services. We maintain strict policies to ensure the privacy of those who
        use our Services ("End Users," "you," or "your") or those who may just
        visit our Site without otherwise using our Services ("Visitors"). This
        policy ("Privacy Policy") describes the types of information we may
        collect from you and our practices for collecting, using, maintaining,
        protecting, and disclosing such information. This Privacy Policy also
        includes a description of certain rights that you may have over
        information that we may collect from you.
      </Typography>

      <Typography variant='body1' gutterBottom>
        By using the Services, you agree to this Privacy Policy. If you do not
        agree with our policies and practices, your choice is to not use our
        Services.
      </Typography>

      <Typography variant='h2' gutterBottom>
        SUMMARY OF DATA COLLECTION, DISCLOSURE, AND SALE
      </Typography>

      <Typography variant='body1' gutterBottom>
        Here is short summary of data the categories of data we have collected,
        disclosed, and sold over the last twelve months. We do not sell data.
        The rest of this Privacy Policy provides more in-depth information on
        our privacy practices.
      </Typography>

      <Typography variant='body1' gutterBottom>
        <b>1.</b> Identifiers, such as contact details, such as real name,
        alias, address, telephone number, unique personal identifiers, online
        identifiers, IP address, email address, and account name.
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we disclose this data as part of our business? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we sell this data? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <b>2.</b> Categories of PI described in the California Consumer Privacy
        Act, GDPR or otherwise (including name, email, phone number, mailing
        address, birthday, SSN, driver's license number, bank account number,
        health information)
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we disclose this data as part of our business? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we sell this data? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <b>3.</b> Commercial information: (Transaction information, purchase
        history, financial details, and payment information)
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? No' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we disclose this data as part of our business? No' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we sell this data? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <b>4.</b> Geolocation data: (device location)
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we disclose this data as part of our business? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we sell this data? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <b>5.</b> Internet or other electronic network activity information:
        (Browsing history, search history, online behavior, interest data, and
        interactions with our and other websites, applications, systems, and
        advertisements)
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we disclose this data as part of our business? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we sell this data? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <b>6.</b> Inferences drawn from other personal information to create a
        profile about a consumer: (for example, an individual's preferences and
        characteristics)
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we disclose this data as part of our business? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we sell this data? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <b>7.</b> Biometric information
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <b>8.</b> Characteristics of protected classifications under California
        or United States federal law
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <b>9.</b> Audio, visual or similar information
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <b>10.</b> Professional or employment related information
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <b>11.</b> Non-public education information (per the Family Educational
        Rights and Privacy Act)
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? No' />
        </ListItem>
      </List>

      <Typography variant='h2' gutterBottom>
        INFORMATION THAT CORNERSTONE COLLECTS
      </Typography>

      <Typography variant='h3' gutterBottom>
        TYPES OF INFORMATION COLLECTED
      </Typography>

      <Typography variant='h4' gutterBottom>
        1. PERSONAL DATA
      </Typography>

      <Typography variant='body1' gutterBottom>
        "Personal Data" is information by which you may be personally
        identified. Cornerstone may collect the following Personal Data from
        you:
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Name' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Email' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Company of employment name and information' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='Any other information voluntarily supplied by you to us reasonably
          necessary to perform the Services (optional).'
          />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        Your bank account information, payment information, social security
        number, or driver's license number may be collected by third-party
        vendors, including our payment processors. Such identifying information
        is not collected or stored by Cornerstone. Your data may be processed,
        collected, shared or stored by third-party vendors, including website
        service providers, website analytics providers, third party payment
        processors, or other service providers we use to conduct business. Such
        processing, collection, sharing or storing of data by such third parties
        is not processed, collected, shared or stored by Cornerstone, with the
        exception of our analytics service provider as we use such services to
        assist in better understanding the Visitors on our Site.
      </Typography>

      <Typography variant='h4' gutterBottom>
        2. NON-PERSONAL DATA
      </Typography>

      <Typography variant='body1' gutterBottom>
        Non-personal data includes any data that cannot be used on its own to
        identify, trace, or identify a person. We may collect your device
        information, including GPS data and IP address
      </Typography>

      <Typography variant='h2' gutterBottom>
        HOW WE COLLECT INFORMATION
      </Typography>

      <Typography variant='body1' gutterBottom>
        We collect information when you provide it do us directly through an
        interaction with us; for example:
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='When you apply or register for the service' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='When you fill out contact or registration forms' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='When you fill out feedback forms' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='When you contact us through our Site' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='When you contact us for service requests' />
        </ListItem>
      </List>

      <Typography variant='h2' gutterBottom>
        WHY WE COLLECT AND HOW WE USE YOUR INFORMATION
      </Typography>

      <Typography variant='body1' gutterBottom>
        We collect and use your Personal Data when we have a legitimate purpose
        to do so, including the following reasons:
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='To verify your eligibility to use the services' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='When it is necessary for the general functioning of the Services,
          including to contact you'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='When it is necessary in connection with any contract you have entered
          into with us or to take steps prior to entering into a contract with
          us'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary="When we have obtained your or a third party's prior consent" />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='When we have a legitimate interest in processing your information for
          the purpose of providing or improving our services'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='When we have a legitimate interest in using the information for the
          purpose of contacting you, subject to compliance with applicable law'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='When we have a legitimate interest in using the information for the
          purpose of detecting, and protecting against, breaches of our policies
          and applicable laws'
          />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        We may use aggregated (anonymized) information about our End Users, and
        information that does not identify any individual, without restriction.
      </Typography>

      <Typography variant='h2' gutterBottom>
        INFORMATION COLLECTED FROM THIRD PARTIES
      </Typography>

      <Typography variant='body1' gutterBottom>
        Information from our service providers: We may receive information about
        you from third-party service providers that we engage for marketing our
        products and services.
      </Typography>
      <Typography variant='body1' gutterBottom>
        Information from social media sites and other publicly available
        sources: When you choose to login or sync your account through a
        third-party account, or interact or engage with us on social media
        sites, or other social platforms, through posts, comments, questions,
        instant messaging, and other interactions, we may collect such publicly
        accessible information to allow us to connect with you, improve our
        products, or better understand user reactions and issues. Once
        collected, this information may remain with us even if you delete it
        from the social media sites. We may also add and update information
        about you, from other publicly available sources.
      </Typography>
      <Typography variant='body1' gutterBottom>
        When non-Personal Data you give to us is combined with Personal Data we
        collect about you, it will be treated as Personal Data and we will only
        use it in accordance with this Privacy Policy.
      </Typography>

      <Typography variant='h2' gutterBottom>
        ACCESSING AND CONTROLLING YOUR INFORMATION
      </Typography>

      <Typography variant='body1' gutterBottom>
        If you would like to prevent us from collecting your information
        completely, you should cease use of our Services. You can also control
        certain data via these other methods:
      </Typography>
      <Typography variant='body1' gutterBottom>
        Correction capabilities: You have the ability to access and correct any
        inaccuracies in your personally identifiable information by contacting
        us at <a href='mailto:contact@csiwired.com'>contact@csiwired.com</a>.
      </Typography>
      <Typography variant='body1' gutterBottom>
        Opt-out of non-essential electronic communications: You may opt out of
        receiving newsletters and other non- essential messages by using the
        'unsubscribe' function included in all such messages or by contacting us
        directly at{' '}
        <a href='mailto:contact@csiwired.com'>contact@csiwired.com</a>. However,
        you will continue to receive notices and essential transactional emails.
      </Typography>
      <Typography variant='body1' gutterBottom>
        Optional information: You can always choose not to fill in non-mandatory
        fields when you submit any form linked to our services.
      </Typography>
      <Typography variant='body1' gutterBottom>
        Under the United States California Consumer Privacy Act, the Virginia
        Consumer Data Protection Act, Nevada's privacy laws, and other
        state-specific consumer privacy laws regarding notice of information
        collected, residents of these states have statutory data rights. We
        provide the same control and rights over your data no matter where you
        choose to live in the United States. As a user of Cornerstone, you have
        the following control over your data:
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary="Right to access: You have the right to access (and obtain a copy of,
          if required) the categories of personal information that we hold about
          you, including the information's source, purpose and period of
          processing, and the persons to whom the information is shared."
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='Right to rectification: You have the right to update the information
          we hold about you or to rectify any inaccuracies. Based on the purpose
          for which we use your information, you can instruct us to add
          supplemental information about you in our database.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='Right to erasure: You have the right to request that we delete your
          personal information in certain circumstances, such as when it is no
          longer necessary for the purpose for which it was originally
          collected.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='Right to restriction of processing: You may also have the right to
          request to restrict the use of your information in certain
          circumstances, such as when you have objected to our use of your data
          but we need to verify whether we have overriding legitimate grounds to
          use it.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='Right to data portability: You have the right to transfer your
          information to a third party in a structured, commonly used and
          machine-readable format, in circumstances where the information is
          processed with your consent or by automated means.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary="Right to object: You have the right to object to the use of your
          information in certain circumstances, such as the use of your personal
          information for direct marketing If you are a resident of the European
          Economic Area (EEA), you may have certain rights under the European
          Union's General Data Protection Regulation (GDPR). The information
          collected from you by us must be explained as follows in order for
          GDPR compliance. Please email us if you have any questions or want to
          exercise your rights in accordance with this Privacy Policy under
          these applicable laws."
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary="Cornerstone's legal basis for the processing your data includes the
          necessity to contract with you and perform our Services."
          />
        </ListItem>

        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary="Cornerstone's legitimate interests in collecting such data include
          legitimate interests of our business, including but not limited to
          performing Services for you and other End Users, collecting necessary
          information in order to safely and accurately perform such Services,
          fraud prevention, information security, direct marketing, and legal
          compliance."
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='Cornerstone does not collect categories of personal data from sources
          other than yourself.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='Cornerstone may transfer personal data outside of the jurisdiction,
          such as in the United, where we conduct our Services.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='Cornerstone stores this information for as long as necessary to
          conduct Services. We will delete any data that has not been used for a
          period of five (5) years or longer.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='Cornerstone does not use automated decision-making, such as profiling,
          during your application process.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='If you are a citizen where which GDPR applies, you have the right to
          object to or restrict data processing, the right to erasure also known
          as the right to be forgotten, and the right to data portability.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='At any time, you have the right to withdraw your consent for us to
          use, store, or collect your data and file a complaint with your local
          supervisory authority.'
          />
        </ListItem>
      </List>

      <Typography variant='h2' gutterBottom>
        EXERCISE YOUR DATA RIGHTS
      </Typography>

      <Typography variant='body1' gutterBottom>
        We acknowledge your right to request access, amendment, or deletion of
        your data. We also recognize that you have the right to prohibit sale of
        your data, but we do not sell data.
      </Typography>
      <Typography variant='body1' gutterBottom>
        You can exercise the rights described above by sending an email or mail
        to the addresses listed in the Questions and Comments section below.
        Only you, or an agent authorized to make a request on your behalf, may
        make a request related to your personal information.
      </Typography>
      <Typography variant='body1' gutterBottom>
        We cannot respond to your request if, (i) we cannot verify your
        identity; or (ii) your request lacks sufficient details to help us
        handle the request. We will make best efforts to respond to your request
        withing forty five (45) days of its receipt. If we cannot respond in
        forty five (45) days, we will inform you, in writing, the reason for the
        delay and will respond to your request within 90 days. Any information
        we provide will only cover the twelve (12)-month period preceding the
        request's receipt.
      </Typography>

      <Typography variant='body1' gutterBottom>
        We do not charge a fee to process or respond to your request unless it
        is excessive, repetitive, or manifestly unfounded. If we determine that
        the request warrants a fee, we will tell you why we made that decision
        and provide you with a cost estimate before completing your request. We
        are not obligated to provide responses to your data requests more than
        twice in a twelve (12)-month period.
      </Typography>

      <Typography variant='h2' gutterBottom>
        AUTOMATED DATA COLLECTION METHODS
      </Typography>

      <Typography variant='body1' gutterBottom>
        Cookies. A cookie is a small file placed on the hard drive of your
        computer. We use cookies to help manage our website and report on your
        interaction with the Site.
      </Typography>
      <Typography variant='body1' gutterBottom>
        Log Files. We use means through the Services to collect IP addresses,
        location data, and access times.
      </Typography>

      <Typography variant='h2' gutterBottom>
        HOW LONG DO WE STORE PERSONAL DATA?
      </Typography>

      <Typography variant='body1' gutterBottom>
        We will only retain your Personal Data for as long as is necessary to
        fulfil the purposes for which it is collected. This length of time may
        vary according to the nature of your relationship with us. Personal Data
        will be purged after five (5) years of nonuse of an account.
      </Typography>

      <Typography variant='h2' gutterBottom>
        USERS UNDER THE AGE OF 13
      </Typography>

      <Typography variant='body1' gutterBottom>
        Our Services are not intended for anyone under 18, particularly children
        under 13 years of age, and we do not knowingly collect Personal Data
        from children under 18. Participation in our Services is limited to
        individuals of 18 years old or older (or the age of majority in your
        jurisdiction). If you are under 18, do not use or register on the
        Services, use any of the interactive submission features on our Site, or
        provide any information about yourself to us. If we learn we have
        collected or received Personal Data from a child under 18 without
        verification of parental consent, we will delete that information. If
        you believe we might have any information from or about a child under
        18, please contact us at the email address listed below.
      </Typography>

      <Typography variant='h2' gutterBottom>
        DO NOT TRACK SETTINGS
      </Typography>

      <Typography variant='body1' gutterBottom>
        We do not track our Users over time and across third party websites to
        provide targeted advertising and do not specifically respond to Do Not
        Track ("DNT") signals.
      </Typography>

      <Typography variant='h2' gutterBottom>
        WHO WE SHARE DATA WITH
      </Typography>

      <Typography variant='body1' gutterBottom>
        We may use aggregated (anonymized) information about our End Users and
        Visitors, and information that does not identify any individual, without
        restriction.
      </Typography>
      <Typography variant='body1' gutterBottom>
        We do not sell Personal Data specific personal or transactional
        information to anyone except as described below.
      </Typography>

      <Typography variant='h3' gutterBottom>
        1. AFFILIATES AND SUBSIDIARIES
      </Typography>

      <Typography variant='body1' gutterBottom>
        We may, for our legitimate interests, share your information with
        entities under common ownership or control with us who will process your
        information in a manner consistent with this Privacy Policy and subject
        to appropriate safeguards. Such parent companies, affiliates, or
        subsidiaries may be located in the United States.
      </Typography>

      <Typography variant='h3' gutterBottom>
        2. SUCCESSORS IN INTEREST
      </Typography>

      <Typography variant='body1' gutterBottom>
        We may, for our legitimate interests, share your information with a
        buyer or other successor in the event of a merger, divestiture,
        restructuring, reorganization, dissolution, or other sale or transfer of
        some or all of our assets, in which Personal Data about our End Users is
        among the assets transferred. You will be notified of any such change by
        a prominent notice displayed on our Services or by e-mail. Any successor
        in interest to this Privacy Policy will be bound to the Privacy Policy
        at the time of transfer.
      </Typography>

      <Typography variant='h3' gutterBottom>
        3. LAW ENFORCEMENT AND OTHER GOVERNMENTAL AGENCIES
      </Typography>

      <Typography variant='body1' gutterBottom>
        We may share your information when we believe in good faith that such
        sharing is reasonably necessary to investigate, prevent, or take action
        regarding possible illegal activities or to comply with legal process.
        This may involve the sharing of your information with law enforcement,
        government agencies, courts, and other organizations.
      </Typography>

      <Typography variant='h3' gutterBottom>
        4. SERVICE PROVIDERS
      </Typography>

      <Typography variant='body1' gutterBottom>
        We may, for our legitimate interests, share certain information with
        contractors, service providers, third party authenticators, and other
        third parties we use to support our business and who are bound by
        contractual obligations to keep Personal Data confidential and use it
        only for the purposes for which we disclose it to them. Some of the
        functions that our service providers provide are as follows:
      </Typography>

      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Server infrastructure and storage' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Business analytics services' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='User and identity verification management' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Payment processing' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            primary='Site log analytics service for activity, performance, and
          troubleshooting'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Marketing, sales, and service management' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Conducting our services' />
        </ListItem>

        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Email management services' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        We may use and disclose your personal data that you have voluntarily
        provided to us to third parties who contract with us to conduct our
        Services. We hereby acknowledge that any such disclosure shall be in
        compliance with all applicable data privacy laws in any given disclosure
        ("Applicable Law(s)") and further acknowledge compliance with any such
        policies of covered third party entities we may work with.
      </Typography>

      <Typography variant='h2' gutterBottom>
        THIRD-PARTY SERVICES AND WEBSITES
      </Typography>
      <Typography variant='body1' gutterBottom>
        Cornerstone is not responsible for the privacy policies or other
        practices employed by websites linked to, or from, our Site nor the
        information or content contained therein, and we encourage you to read
        the privacy statements of any linked third party. This includes sharing
        information via social media websites.
      </Typography>

      <Typography variant='h2' gutterBottom>
        DATA STORAGE AND HOW CORNERSTONE PROTECTS YOUR INFORMATION
      </Typography>
      <Typography variant='body1' gutterBottom>
        Cornerstone stores basic End User data on our servers including name,
        email, username, and company name. Payments are not always required by
        End Users. If an End User makes a purchase and a payment is required,
        then payment information is processed and stored by our partners or
        service providers. Personal Data about End Users and Visitors is stored
        within the United States. The Services are only intended to be used
        inside the United States; we do not seek users from outside this
        location at this time. If you are using the Services from the EEA or
        other regions with laws governing data collection and use, please note
        that you are agreeing to the transfer of your Personal Data to the
        United States. Such locations may have laws which are different, and
        potentially not as protective, as the laws of your own country. By
        providing your Personal Data, you consent to any transfer and processing
        in accordance with this Privacy Policy. For the avoidance of doubt, our
        Site and Services are not intended for any users outside of the United
        States.
      </Typography>

      <Typography variant='body1' gutterBottom>
        Cornerstone employs physical, electronic, and managerial control
        procedures to safeguard and help prevent unauthorized access to your
        information. We choose these safeguards based on the sensitivity of the
        information that we collect, process and store and the current state of
        technology. Our outsourced service providers who support our operations
        are also vetted to ensure that they too have the appropriate
        organizational and technical measures in place to protect your
        information.
      </Typography>

      <Typography variant='body1' gutterBottom>
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we do our best to protect your Personal
        Data, we cannot guarantee the security of your information transmitted
        to the Services. Any transmission of information is at your own risk. We
        are not responsible for circumvention of any privacy settings or
        security measures contained on the Services. In the event that there is
        breach in the information that we hold; we shall notify of such breach
        via email or via notice on the Services.
      </Typography>

      <Typography variant='h2' gutterBottom>
        CHANGES TO THE PRIVACY POLICY
      </Typography>
      <Typography variant='body1' gutterBottom>
        It is our policy to post any changes we make to our Privacy Policy on
        this page of the Site. If we make material changes to how we treat our
        End Users' or Visitors' Personal Data, we will notify you by email to
        the primary email address specified in your account or through a
        prominent notice on the Site. Such changes will be effective when
        posted. The date the Privacy Policy was last revised is identified at
        the top of the page. Your continued use of our Services following the
        posting of any modification to this Privacy Policy shall constitute your
        acceptance of the amendments to this Privacy Policy. You can choose to
        delete the Site and discontinue of use the Service if you do not accept
        any modified version of this Privacy Policy.
      </Typography>

      <Typography variant='h2' gutterBottom>
        QUESTIONS AND COMMENTS
      </Typography>
      <Typography variant='body1' gutterBottom>
        If you have any questions or comments about this Privacy Policy, or if
        you would like to file a request about the data we hold or file a
        deletion request, please contact Customer Support at
        <a href='mailto:contact@csiwired.com'>&nbsp;contact@csiwired.com</a> .
      </Typography>
    </Box>
  );
};
export const privacyPolicyTitle = 'PRIVACY POLICY';

function PrivacyPolicyDialog({ open, setOpen }) {
  return (
    <GeneralUseDialog
      open={open}
      setOpen={setOpen}
      contentTitle={privacyPolicyTitle}
      contentText={<PrivacyPolicyText />}
    />
  );
}

export default PrivacyPolicyDialog;
