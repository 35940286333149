import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import {
  Drawer,
  SwipeableDrawer,
  Box,
  DialogTitle,
  IconButton,
} from '@mui/material';
import Cancel from '@mui/icons-material/Cancel';
import { HEADER_HEIGHT } from '../../LayoutTools/Header';
import { isMobile } from 'react-device-detect';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dragger: {
    width: '5px',
    cursor: 'ew-resize',
    padding: '10px 0 0',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 100,
  },
  drawerTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 !important',
  },
}));

export const defaultDrawerWidth = '30vw';
const minDrawerWidth = 185;
const maxDrawerWidth =
  typeof window !== 'undefined' ? window.innerWidth * 0.9 : 1000;

function InfoDrawer(props) {
  const { ref, open, setOpen } = props;
  const { classes } = useStyles();
  const [drawerWidth, setDrawerWidth] = useState(defaultDrawerWidth);
  const [allowTextSelect, setAllowTextSelect] = useState(true);

  const handleMouseDown = (e) => {
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
    setAllowTextSelect(false);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
    setAllowTextSelect(true);
  };

  const handleMouseMove = useCallback((e) => {
    const newWidth =
      document.body.offsetLeft + document.body.offsetWidth - e.clientX + 20;
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      setDrawerWidth(newWidth);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    openDrawer: () => setOpen(true),
  }));

  const closeDrawer = () => {
    setDrawerWidth('30%');
    setOpen(false);
  };

  if (!open) return false;

  if (isMobile) {
    return (
      <SwipeableDrawer
        PaperProps={{
          sx: {
            marginTop: `${HEADER_HEIGHT}px`,
            padding: '16px',
            height: `calc(100% - ${HEADER_HEIGHT}px)`,
          },
        }}
        anchor='right'
        open={open}
        onClose={() => setOpen(false)}
        allowSwipeInChildren={true}
        disableDiscovery={true}
        disableSwipeToOpen={true}
      >
        {props.children}
      </SwipeableDrawer>
    );
  } else {
    return (
      <Drawer
        PaperProps={{
          sx: {
            width: drawerWidth,
            marginTop: `${HEADER_HEIGHT}px`,
            padding: '16px',
            height: `calc(100% - ${HEADER_HEIGHT}px)`,
            userSelect: allowTextSelect ? 'auto' : 'none',
          },
        }}
        anchor='right'
        open={open}
        onClose={closeDrawer}
        hideBackdrop={true}
        variant='persistent'
      >
        <Box
          onMouseDown={(e) => handleMouseDown(e)}
          className={classes.dragger}
        />
        <DialogTitle className={classes.drawerTitle}>
          <IconButton onClick={closeDrawer}>
            <Cancel />
          </IconButton>
        </DialogTitle>
        {props.children}
      </Drawer>
    );
  }
}

export default forwardRef(InfoDrawer);
